.authorization {
  .row {
    margin: 0px 20px;
  }

  .card-container {
    margin: 30px auto;
  }

  .floIcon {
    margin-bottom: 20px;

    .title {
      font-size: 2rem;
      font-weight: 100;
      color:         $primaryDarkColor;
      margin-top: 10px;
    }

    img {
      max-width: 30%;
    }
  }

  h3 {
    color: $primaryDarkColor;
  }

  h4 {
    color: $secondaryDarkColor;
  }

  .details {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul.details-list {
    list-style-type: disc;
  }

  .buttons {
    @extend .text-center;

    .btn {
      margin: 0px 5px;
    }
  }
}
